import { useContext } from "react";
import { Link } from "react-router-dom";
import { globalContext } from "../../context";

export default function RecipeItem({ item }) {
  const { handleAddToFavorite, favoritesList } = useContext(globalContext);

  const isFavorite =
    favoritesList.findIndex((favItem) => favItem.id === item.id) !== -1;

  return (
    <div className="flex flex-col w-3/12 overflow-hidden p-5 bg-white/75 shadow-xl gap-5 border-2 rounded-2xl border-white">
      <div className="h-40 flex justify-center overflow-hidden items-center rounded-xl">
        <img src={item?.image_url} alt="recipe item" className="block w-full" />
      </div>
      <div>
        <span className="text-sm text-cyan-700 font-medium">
          {item?.publisher}
        </span>
        <h3 className="font-bold text-2xl truncate text-black">
          {item?.title}
        </h3>
        <Link
          to={`/recipe-item/${item?.id}`}
          className="text-sm p-3 mt-5 px-2 rounded-lg uppercase font-medium tracking-wider inline-block shadow-l bg-black text-white"
        >
          Recipe Details
        </Link>
        <button
          onClick={() => handleAddToFavorite(item)}
          className="p-3 px-2 rounded-lg text-sm ml-2 uppercase font-medium tracking-wider  inline-block shadow-md bg-black text-white"
        >
          {isFavorite ? "Remove from favorites" : "Add to favorites"}
        </button>
      </div>
    </div>
  );
}
